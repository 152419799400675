// import _axios from "./request"
import Qs from 'qs';
import { buildParams } from '@/utils/sign';
import v2request from './v2request';

export function wxLoginInfo(data) {
  console.log(Qs.stringify(data));
  data = buildParams(data);
  return v2request({
    method: 'get',
    url: '/user/bind/wechat/',
    //   data: Qs.stringify(data)
    params: data,
  });
}
