<template>
  <div class="withdrawal">
    <div class="w-header">
      <div class="w-icon" @click="toHome">
        <span class="iconfont icon-left"></span>
      </div>
      <div class="w-title">提现</div>
    </div>
    <!-- <div class="w-type">
            <span>现金</span>
        </div> -->
    <!-- v-for="(item, index) in moneyValue" -->
    <div class="w-main">
      <!-- <div>
        <button
          :disabled="balance<item"
          @click="selectMoney(index)"
          class="w-money"
          :class="{ 'w-active': currentIndex == index && balance >= item}"
          v-for="(item, index) in [1,2,3,4,5,6]"
          :key="index"
          >{{ item }}元</button
        >
      </div> -->
      <div>
        <!-- :disabled="balance < item" -->
        <button v-for="(item, index) in moneyValue" :key="index" :class="{ 'w-active': currentIndex == index }" class="w-money" @click="selectMoney(index)">{{ item }}元</button>
      </div>
    </div>
    <p class="w-btn">
      <span @click="toWithdrawal">确认提现</span>
    </p>
    <p v-if="!wechat" class="w-wx" @click="toWxBind">
      <span class="iconfont icon-wx"></span>
      <span class="text">绑定微信号</span>
      <span class="iconfont icon-right"></span>
    </p>
    <p v-if="wechat" class="w-wx">
      <span class="w-logo">
        <img :src="wechat.headimgurl" alt="" @error="imgError" />
      </span>
      <span class="w-name">{{ wechat.nickname }}</span>
      <span class="w-status">已绑定</span>
    </p>
    <div v-if="showBindGuide" class="wx-bind">
      <div class="bind-shade"></div>
      <div class="bind-content">
        <div class="close" @click="showBindGuide = false">
          <img src="@/assets/imgs/x-btn.png" alt="" />
        </div>
        <h3 class="title">微信绑定步骤</h3>
        <div class="text">
          <p>1、打开微信，搜公众号<span class="light-text">“微游Club”</span></p>
          <p>2、关注<span class="light-text">“微游Club”</span>公众号</p>
          <p>3、点击<span class="light-text">“自助服务”</span> - <span class="light-text">“绑定微信”</span> 菜单</p>
          <p>4、输入绑定码，进行绑定</p>
          <p>5、绑定后返回游戏，即可提现</p>
        </div>
        <div class="exchange">
          <p>
            绑定码：<span class="light-text"> {{ exchangeCode }} </span>
          </p>
          <button v-clipboard:copy="exchangeCode" v-clipboard:success="onCopy" v-clipboard:error="onError" type="button">复制绑定码</button>
        </div>
      </div>
    </div>
    <!-- <p class="w-error">
            <span class="text">若绑定微信账号失败</span>
            <span class="to">点击这里</span>
        </p> -->
    <div class="w-msg" v-html="transfer_rule">
      <!-- <p>提示:</p>
            <p>1、本次活动提供红包金额按120%充值游戏元宝。100元红包金额=1200元宝(非绑定)。元宝只能用于游戏内消耗，不能兑换为现金，确认兑换后，1个工作日内到账游戏角色;</p>
            <p>2、红包金额直接提现到微信余额，100元红包金额=100元现金。确认提现后，3个工作日内到账微信余额(没有实名开通微信支付的用户将无法发放提现现金) ;</p>
            <p>3、兑换或提现申请一-经提交，将无法修改; (元宝无法 兑换为现金)</p>
            <p>4、请在活动时间结束前完成提现，活动时间结束后未提现金额将会全部清0。</p> -->
    </div>
  </div>
</template>

<script>
import { redConfig, withdrawRedPackage, getWxMpConfig, getWithdrawConfig } from '@/api/redList';
import { wxLoginInfo } from '@/api/wxlogin';
import { getUserInfo } from '@/api/redList';
import { getGameConfig } from '@/api/gameConfig';

export default {
  name: 'Withdrawal',
  data() {
    return {
      moneyValue: [],
      currentIndex: 0,
      wechat: null,
      transfer_rule: '',
      timeId: '',
      balance: '',
      headimgurl: require('@/assets/imgs/wechat.jpg'),
      showBindGuide: false,
      exchangeCode: 'xxxxxxx',
      activity_rule_type: '',
    };
  },
  created() {
    // 获取用户信息
    this.httpGetUserInfo();
  },
  mounted() {},
  activated() {
    console.log('路由参数', this.$route.query);
    const { activity_rule_type } = this.$route.query;
    if (activity_rule_type) {
      this.activity_rule_type = activity_rule_type;
    }
    console.log('this.activity_rule_type', this.activity_rule_type);
    // 获取红包配置
    this.httpGetRedConfig();
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  methods: {
    onCopy() {
      this.$toast('复制成功');
    },
    onError() {
      this.$toast('复制失败');
    },
    imgError(event) {
      let img = event.srcElement;
      img.src = require('@/assets/imgs/wechat.jpg');
      img.onerror = null; //防止闪图
    },
    // 回退
    toHome() {
      this.moneyValue.splice(0, this.moneyValue.length);
      this.transfer_rule = '';
      this.currentIndex = 0;
      // this.$router.back();
      this.$router.push('/withdrawalType');
    },
    // 选择金额
    selectMoney(index) {
      this.currentIndex = index;
      // console.log(this.moneyValue[index])
    },
    // TODO
    // 提现操作
    toWithdrawal() {
      if (!this.wechat) {
        this.$toast('请先绑定微信', { duration: 1500 });
        return;
      }
      withdrawRedPackage({
        app_id: window.sessionStorage.getItem('appid'),
        withdraw_amount: this.moneyValue[this.currentIndex],
        token: window.sessionStorage.getItem('ato'),
        activity_rule_type: this.activity_rule_type,
      })
        .then((res) => {
          this.$bus.$emit('getUserInfoEvent');
          this.$toast(res.data.msg, { duration: 2500 });

          console.log('提现', res.data);
        })
        .catch((err) => {
          console.log('提现', err);
        });
      // console.log(this.currentIndex)
    },
    // 获取红包配置
    httpGetRedConfig() {
      getWithdrawConfig({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
        activity_rule_type: this.activity_rule_type,
        // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyIjp7Im9wZW5pZCI6Ik1UQXdNRE10TVRBd01EY18iLCJyb2xlX2lkIjoiTVRBd01ETXRNVEF3TURjXyIsInNlcnZlcl9pZCI6IjEifSwiYXVkIjoiNWY5MTNmYjJkODI3OSIsImlzcyI6IjVmOTEzZmIyZDgyOGQiLCJleHAiOjE2MDU5NDY1NDYsImp0aSI6Ik1UQXdNRE10TVRBd01EY18ifQ.jDexdRjlgdQ0_ddXNnLCxBlMXduN4irpDVq-28a4PCNArCk_7Wou4aC1CzEGj5_gm1HpiJHMTF4cDGJIYWo_fgcuLJFnS6KHxOFKepInbYJ-xr_MZPRSCA8UysOFQ0ja7fBcaCVnc89DMEnccCccSR4Wdxpa-ZF9NNk4V24YitsTqRqGsaJ__obT1q5Oi7jGUIDAuxC4uoiTeF70HMCVGwovHqRk4Ji0gARe3wUptWMQToM7oZauTqEJUHfzg1fCK0pw8NqMGRMRxNAWwYVISZn5MlCt3dfrwchf-qH-oRIEf6H4oFM3sXu8vZ1N3StFry32Iz6hPnZGgRnp03ZsPQ"
      }).then((res) => {
        console.log('红包配置', res.data);
        let mList = res.data.data.amounts;
        let alist = [];
        for (let i of mList) {
          if (i != 0) {
            alist.push(i);
          }
        }
        this.moneyValue = alist;
        this.transfer_rule = res.data.data.tips;
      });
    },
    // 微信绑定
    toWxBind() {
      window.getWxParams = function (data) {
        data = JSON.parse(data);
        console.log('data', data);
        console.log('session', window.sessionStorage);
        wxLoginInfo({
          app_id: window.sessionStorage.getItem('appid'),
          code: data.code,
          token: window.sessionStorage.getItem('ato'),
        })
          .then((res) => {
            // window.location.reload()
            window.sessionStorage.setItem('getUserInfo', 1);
            window.android ? window.android.showToast(res.data.msg) : '';
            if (res.data.msg == '审核中') {
              let msg = `本次提现${this.moneyValue[this.currentIndex]}元，将于24小时内审核到账（可在明细查询审核进度）`;
              this.$toast(msg, { duration: 2500 });
            } else {
              this.$toast(res.data.msg, { duration: 1500 });
            }
            // this.$bus.$emit("getUserInfoEvent")
            console.log('微信绑定', res.data);
          })
          .catch((err) => {
            console.log('错误', err);
          });
      };
      let that = this;
      if (!that.timeId) {
        that.timeId = setInterval(function () {
          if (window.sessionStorage.getItem('getUserInfo')) {
            // that.$bus.$emit("getUserInfoEvent")
            that.httpGetUserInfo();
            clearInterval(that.timeId);
          }
        }, 300);
      }

      if (!window.android) {
        getGameConfig({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        }).then((res) => {
          console.log('res: ', res);
          const { data: content } = res;
          const { data, status } = content;
          if (status === 200) {
            if (data.bind_type === 'jump') {
              window.android.callWeixinLogin();
            } else {
              this.showBindGuidePage();
            }
          }
        });
      } else {
        this.showBindGuidePage();
      }
    },
    showBindGuidePage() {
      getWxMpConfig({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      }).then((res) => {
        if (res.data.status === 200) {
          this.exchangeCode = res.data?.data?.bind_key;
          this.showBindGuide = true;
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    // 获取微信信息
    httpGetUserInfo() {
      getUserInfo({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      })
        .then((res) => {
          console.log('获取微信信息', res.data.data.wechat);
          res.data.data.wechat ? (this.wechat = res.data.data.wechat) : (this.wechat = null);

          this.balance = res.data.data.balance;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawal {
  // background-color: #fff;

  background-color: #f7f7f7;
  min-height: 100vh;
  .w-header {
    background-color: #fff;
    position: relative;
    height: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    .w-icon {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      .icon-left {
        font-size: 0.34rem;
        color: #333;
        margin-left: 0.3rem;
      }
    }
    .w-title {
      flex: 1;
      font-size: 0.32rem;
    }
  }
  .w-type {
    display: flex;
    justify-content: center;
    // justify-content: center;
    margin-top: 0.2rem;
    span {
      width: 1.3rem;
      height: 0.6rem;
      color: #fff;
      line-height: 0.6rem;
      border-radius: 0.1rem;
      display: inline-block;
      text-align: center;
      background-color: #ff4444;
    }
  }
  .w-main {
    padding: 0 0.2rem;

    div {
      width: 100%;
      text-align: center;
      // margin: 0 auto;
      margin-top: 0.3rem;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-around;
      // align-content: c;
    }
    .w-money {
      border: 0.03rem solid #e5e5e5;
      background-color: #f7f7f7;
      color: #151524;
      outline: none;
      margin-right: 0.2rem;
      margin-bottom: 0.2rem;
      font-size: 0.3rem;
      line-height: 0.7rem;
      text-align: center;
      width: 1.8rem;
      height: 0.76rem;
      display: inline-block;
      border-radius: 0.06rem;
    }
    .w-money.w-active {
      border: 0.03rem solid #ff9a30;
      background: #ff9a30;
      color: #ffffff;
    }
  }
  .w-btn {
    user-select: none;
    text-align: center;
    margin-top: 0.4rem;
    span {
      width: 3.2rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      line-height: 0.8rem;
      color: #fff;

      background: linear-gradient(270deg, #ec664b 0%, #ff8555 100%);
      display: inline-block;
    }
  }
  .w-wx {
    height: 0.8rem;
    display: flex;
    margin-top: 0.3rem;
    padding: 0 0.32rem;
    align-items: center;
    background-color: #fff;
    .icon-wx {
      color: #28de85;
      font-size: 0.38rem;
      margin-right: 0.26rem;
    }
    .text {
      font-size: 0.3rem;
      flex: 1;
    }
    .icon-right {
      font-size: 0.32rem;
    }

    .w-logo {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 0.2rem;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .w-name {
      font-size: 0.3rem;
      flex: 1;
    }
    .w-status {
      font-size: 0.3rem;
    }
  }
  .w-error {
    padding: 0 0.32rem;
    // float: right;
    display: flex;
    margin-top: 0.1rem;
    justify-content: flex-end;
    .text {
      margin-right: 0.16rem;
    }
    .to {
      color: #ff434b;
    }
  }
  .w-msg {
    margin-top: 0.2rem;
    padding: 0 0.32rem;
    color: #9b9b9b;
  }

  .wx-bind {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 111;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .bind-shade {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(97, 90, 90, 0.255);
    }
    .bind-content {
      position: absolute;
      z-index: 111;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 0.6rem 0.4rem;
      width: 5.4rem;
      border-radius: 0.3rem;
      .title {
        font-size: 0.36rem;
        color: #454545;
        text-align: center;
        margin-bottom: 0.34rem;
        font-weight: 400;
      }
      .text > p {
        font-size: 0.28rem;
        color: #454545;
        line-height: 0.4rem;
        margin-bottom: 0.14rem;
      }
      .close {
        position: absolute;
        top: 0.24rem;
        right: 0.34rem;
        font-size: 0.38rem;
        width: 0.32rem;
        height: 0.32rem;
        color: #898787;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .exchange {
        text-align: center;
        margin-top: 0.5rem;
        > p {
          font-size: 0.28rem;
          color: #454545;
          margin-bottom: 0.21rem;
        }
        > button {
          border: none;
          outline: none;
          -webkit-appearance: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          width: 2.6rem;
          height: 0.8rem;
          text-align: center;
          background-color: #2897fb;
          color: #fff;
          font-size: 0.3rem;
          border-radius: 0.4rem;
        }
      }
    }
    .light-text {
      font-size: 0.3rem;
      color: #ff942b;
    }
  }
}
</style>
